<template>
  <section class="container">
    <partner-list></partner-list>
  </section>
</template>

<script>
import PartnerList from '../../components/Partner/PartnerList.vue';
export default {
  components: {
    PartnerList,
  },
};
</script>
