<template>
  <div class="container mt-5">
    <a-row>
      <a-col :span="24">
        <a-row type="flex" justify="space-between" class="mb-3">
          <h4>Hamkorlar ro'yxati</h4>
          <router-link :to="{ name: 'newPartner' }">
            <a-button size="large" type="primary">
              <a-icon type="file-add" />
              Hamkor qo'shish
            </a-button>
          </router-link>
        </a-row>
        <a-table
          :columns="columns"
          :data-source="data"
          bordered
          :scroll="{ x: 300 }"
        >
          <template slot="name" slot-scope="text, record">
            <router-link
              class="text-dark font-weight-bold"
              :to="{
                name: 'editPartner',
                params: {
                  partnerId: record.id,
                },
              }"
              ><a-icon type="edit" /> {{ text }}
            </router-link>
          </template>
          <template slot="image" slot-scope="image">
            <img :src="image" width="100" height="50" />
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Link',
    dataIndex: 'link',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Rasm',

    className: 'column-money',
    dataIndex: 'image',
    scopedSlots: { customRender: 'image' },
  },
];
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      columns: columns,
      data: [],
    };
  },

  methods: {
    ...mapActions({
      getAllPartners: 'partner/getAllPartners',
    }),
  },

  created() {
    this.getAllPartners().then(({ results }) => {
      this.data = results.map((item) => {
        return {
          key: item.id,
          id: item.id,
          link: item.url,
          image: item.image,
        };
      });
    });
  },
};
</script>
